<template>
  <div>
    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="page-header d-flex justify-content-between align-items-center mb-3 pt-4"
          >
            <h1 class="color-sec">Resultados</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Resultados
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content" v-if="turma != undefined">
            <Navigator />

            <!-- <div class="page-info mb-4">
              <div class="row align-items-end">
                <div class="col-md-6">
                  <h2 class="color-ter fs-24 mb-2">{{ provas[0].anoEnsino }} {{ provas[0].turma }}</h2>
                  <p>{{ provas[0].disciplina }}</p>
                </div>

                <div class="col-md-6 text-right">
                  <p class="total"> Questões</p>
                </div>
              </div>
            </div> -->

            <div class="page-info mb-4">
              <div class="row align-items-end">
                <div class="col-md-6">
                  <h2 class="color-ter fs-24 mb-2">{{ turma.tituloTurma }}</h2>
                  <!-- <p>
                    <strong
                      >{{ prova.prova.disciplina.text }} -
                      {{ prova.prova.turma.anoEnsino.titulo }}
                      {{ prova.prova.turma.titulo }}</strong
                    >
                    | {{ prova.prova.dataHoraInicio | formatDate }}
                  </p>
                </div>

                <div class="col-md-6 text-right">
                <p class="total">{{ prova.questoes.length }} Questões</p> -->
                </div>
              </div>
            </div>

            <div class="page-content__charts block__classic p-4 mb-4">
              <div class="row justify-content-center">
                <div class="col-md-4 col-xl-3">
                  <div class="block__pie">
                    <p class="title">
                      Desempenho
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </p>

                    <div class="block__pie--canvas">
                      <div class="app-chart" style="font-size: 30px">
                        <i class="success"></i> Media da turma:
                        <b>{{ turma.mediaTurma }}%</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-xl-3"></div>
                <div class="col-md-4 col-xl-3">
                  <div class="block__pie">
                    <p class="title">
                      Finalização
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </p>

                    <div class="block__pie--canvas">
                      <div class="app-chart">
                        <pie-chart
                          :data="chartDataFinalizados"
                          :options="chartOptions"
                        ></pie-chart>
                      </div>
                    </div>

                    <ul class="list-unstyled text-center">
                      <li>
                        <i class="success"></i>Finalizaram a prova:
                        <strong>{{ percentageDid }}%</strong>
                      </li>
                      <li>
                        <i class="error"></i>Não finalizaram a prova:
                        <strong>{{ percentageDidNot }}%</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input id="search" type="search" class="form-control" />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button class="btn btn-classic">
                        <i class="fa fa-print" /> IMPRIMIR
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__results table-striped table-borderless table-hover text-center"
              >
                <thead>
                  <tr>
                    <th class="text-left pl-4" scope="col">Prova</th>
                    <th scope="col">Data</th>
                    <th scope="col">Média Turma</th>
                    <th scope="col">Desempenho</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody v-if="this.turma">
                  <tr v-for="item in this.turma.provas" :key="item.id">
                    <td class="text-left pl-4" v-html="item.titulo"></td>
                    <td class="text-center">
                      {{ item.dataHoraInicio | formatDate }}
                    </td>
                    <td class="text-center"><strong>{{ item.media.toFixed(2) }}</strong></td>
                    <td class="text-left">
                      <div class="progress-info__content">
                        <div class="progress h-8">
                          <div 
                            class="progress-bar bg-success" 
                            role="progressbar" 
                            :style="`width: ` + percentualAcerto(item.media) + `%`" 
                            :aria-valuenow="percentualAcerto(item.media)" 
                            aria-valuemin="0"
                            aria-valuemax="100"></div>
                          <div
                            class="progress-bar bg-error"
                            role="progressbar"
                            :style="`width: ` + percentualErro(item.media) + `%`" 
                            :aria-valuenow="percentualErro(item.media)" 
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <span
                          class="icon-tooltip"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                          ><i class="help"></i
                        ></span>
                      </div>
                    </td>

                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="goDetalheProvaGeral(item.id)"
                      >
                        <i class="far fa-eye" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import moment from "moment";
import Navigator from "../../../components/resultados/ResultadosNavigator.vue";
import PieChart from "./PieChart.js";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Navigator,
    PieChart,
  },
  data() {
    return {
      showLeftMenu: true,
      provas: [],
      prova: null,
      turma: null,
      percentageCorrect: 0,
      wrongPercentage: 0,
      percentageDid: 0,
      percentageDidNot: 0,
      chartOptions: {
        hoverBorderWidth: 20,
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651"],
            data: [1, 10],
          },
        ],
      },
      chartDataFinalizados: {},
    };
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadProvas();
    // this.loadProva()
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
      } else {
        return "-";
      }
    },
  },
  methods: {
    showResult(id) {
      this.$router.push("resultados-alunos");
    },
    goDetalheProvaGeral(idprova) {
      this.$router.push("/resultados-detalhe-prova-geral/" + idprova);
    },
    loadProvas() {
      this.$api
        .get(
          "desempenho-turma/" +
            this.$route.params.idturma +
            "/" +
            this.$route.params.iddisciplina
        )
        .then((response) => {
          console.log(response);
          this.turma = response.data;
          let c = this.turma.qtdQuestoesCorretas / this.turma.qtdQuestoes;
          let e = this.turma.qtdQuestoesErradas / this.turma.qtdQuestoes;
          this.percentageCorrect = c * 100;
          this.wrongPercentage = e * 100;
          this.chartData = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            datasets: [
              {
                backgroundColor: ["#41B883", "#E46651"],
                data: [
                  this.turma.qtdQuestoesCorretas,
                  this.turma.qtdQuestoesErradas,
                ],
              },
            ],
          };
          let naoFinalizaram =
            this.turma.qtdAlunoProva - this.turma.qtdFinalizacao;
          let fez = this.turma.qtdFinalizacao / this.turma.qtdAlunoProva;
          let naoFez = naoFinalizaram / this.turma.qtdAlunoProva;
          this.percentageDid = fez * 100;
          this.percentageDidNot = naoFez * 100;

          this.chartDataFinalizados = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            datasets: [
              {
                backgroundColor: ["#41B883", "#E46651"],
                data: [this.turma.qtdFinalizacao, naoFinalizaram],
              },
            ],
          };
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    percentualAcerto(media) {
      return media * 10;
    },
    percentualErro(media) {
      return 100 - this.percentualAcerto(media);
    }

    // loadProva() {
    //   console.log(this.$route.params.idprova);
    //   this.$api
    //     .get("estatistica-prova/" + this.$route.params.idprova)
    //     .then((response) => {
    //       this.prova = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>

<style>
.app-chart {
  width: 275px;
  height: 275px;
}
</style>